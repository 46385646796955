<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      class="alert-modal"
      hide-header-close
      content-class="modal-register"
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="d-flex justify-content-center w-100 align-items-center">
          <div class="modal-title">
            {{ isAdd ? "เพิ่มวิธีรับเงิน" : "วิธีการรับเงิน" }}
          </div>
        </div>
      </template>
      <div
        v-if="!isAdd"
        class="d-block refundtype-container d-flex justify-content-center p-3"
      >
        <b-button class="btn-modal btn-hover px-5 ml-1" @click="addRefund"
          >เพิ่ม</b-button
        >
      </div>
      <div v-else>
        <b-tabs v-model="tabIndex">
          <b-tab title="ข้อมูลการรับเงิน">
            <b-row class="mt-3">
              <b-col cols="12" md="6">
                <b-row>
                  <b-col cols="12" md="7">
                    <InputSelect
                      :options="typeList"
                      textField="name"
                      valueField="value"
                      v-model="form.refund_type"
                    >
                      <template v-slot:option-first>
                        <b-form-select-option :value="null" disabled>
                          -- เลือกประเภทการรับเงิน --
                        </b-form-select-option>
                      </template>
                    </InputSelect>
                  </b-col>
                  <b-col cols="12" md="7">
                    <InputSelect
                      :options="typeList"
                      textField="name"
                      valueField="value"
                      v-model="form.bank_type"
                    >
                      <template v-slot:option-first>
                        <b-form-select-option :value="null" disabled>
                          -- เลือกธนาคาร --
                        </b-form-select-option>
                      </template>
                    </InputSelect>
                  </b-col>
                  <b-col cols="12">
                    <InputText
                      v-model="form.bank_name"
                      placeholder="ชื่อบัญชีธนาคาร"
                    ></InputText>
                  </b-col>
                  <b-col cols="12" class="mt-2">
                    <InputText
                      v-model="form.bank_account"
                      placeholder="เลขบัญชีธนาคาร"
                    ></InputText>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-form-checkbox
              class="mt-3"
              v-model="form.is_default"
              :value="1"
              :unchecked-value="0"
            >
              default 
            </b-form-checkbox>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="hide()"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import { minLength, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
    InputSelect,
  },
  props: {},
  data() {
    return {
      isLoading: false,
      showModal: false,
      isAdd: false,
      tabIndex: 0,
      typeList: [
        { value: 1, name: "โอนเข้าบัญชี" },
        { value: 2, name: "Store Credit" },
      ],
      form: {
        refund_type: null,
        bank_type: null,
        bank_account: "",
        bank_name: "",
        is_default: 0,
      },
    };
  },
  validations() {
    return {
      telList: {
        $each: {
          tel: {
            maxLength: maxLength(10),
            minLength: minLength(10),
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      limitOptionalTel: "getLimitTelephone",
    }),
  },

  watch: {
    telList: {
      handler(newVal) {
        newVal.forEach((item) => {
          if (!item.tel) {
            item.ext = "";
          }
        });
      },
      deep: true,
    },
  },

  methods: {
    async show() {
      this.showModal = true;
    },
    addRefund() {
      this.isAdd = true;
    },
    hide() {
      if(this.isAdd){
        this.isAdd = false
      }
      else
      this.showModal = false;
    },

    async save() {
      if(this.isAdd){
        this.isAdd = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .modal-register {
  .btn-link {
    border-color: transparent !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    padding: 0;
    color: #f7f7f7 !important;
  }

  .icon-delete {
    width: 20px;
    height: 20px;
  }
  .register-box {
    background: #f7f7f7;
    border: 1px solid #d8dbe0;

    max-height: 500px;
    overflow: auto;
  }
  .nav-tabs {
    .nav-item {
      margin-bottom: 0px;
    }
    .nav-link {
      border: 1px solid white !important;
      border-bottom: 3px solid white !important;
      font-weight: 600;
      background: white !important;
      color: #333 !important;
    }
    .nav-link.active {
      border: 1px solid var(--secondary-color) !important;
      border-bottom: 3px solid var(--primary-color) !important;
      color: var(--primary-color) !important;
    }
  }
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}
.text-link {
  right: 0;
  position: absolute;
  margin-right: 20px;
}

.refundtype-container {
  border: 1px dashed var(--primary-color) !important;
}
</style>
