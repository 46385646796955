<template>
  <div>
    <b-table
      striped
      hover
      :busy="isBusy"
      :items="items"
      :fields="fields"
      class="table-main table-custom"
      show-empty
      empty-text="No matching records found"
    >
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
  },
  components: {},
  name: "Inventory",
  data() {
    return {
      isBusy: false,
    };
  },
};
</script>
