<template>
  <div class="space-bottom">
    <b-row class="align-items-center">
      <b-col md="4" cols="12">
        <div class="d-flex mt-2">
          <p class="mt-2 mr-2 title" style="flex: 1">เลขที่เอกสาร</p>

          <InputText
            type="text"
            className=" bg-second flex-grow-1"
            placeholder="เลขที่"
            textField=""
            :value="form.rqp_no"
            disabled
          />
        </div>
      </b-col>
      <b-col md="4" cols="12">
        <div class="mt-2">
          <p class="mt-2 mr-2 text-gray text-right">ผู้สร้าง : test</p>
        </div>
      </b-col>
      <b-col md="4" cols="12">
        <div class="mt-2">
          <p class="mt-2 mr-2 title text-right">สถานะ : test</p>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col md="6">
        <div class="d-flex align-items-center f-14">
          <p class="text-gray title-w">ชื่อลูกค้า</p>
          <p class="text-gray">:</p>
          <div class="display-user-value ml-2">
            {{ form.customer_name }}
          </div>
          <font-awesome-icon
            @click="handleSearch(1)"
            :icon="['fas', 'search']"
            class="pointer fa-search text-white"
            id="search-icon"
          />
        </div>
        <div class="d-flex align-items-center mt-1 f-14">
          <p class="text-gray title-w">ชื่อผู้ส่งซ่อม</p>
          <p class="text-gray">:</p>
          <div class="display-user-value ml-2">
            {{ form.technician_name }}
          </div>
          <font-awesome-icon
            @click="handleSearch(2)"
            :icon="['fas', 'search']"
            class="pointer fa-search text-white"
            id="search-icon"
          />
        </div>
        <div class="d-flex align-items-center mt-2 f-14">
          <p class="text-gray title-w">ชื่อผู้รับ</p>
          <p class="text-gray">:</p>
          <div class="display-user-value ml-2">
            {{ form.recive_name }}
          </div>
          <font-awesome-icon
            @click="handleSearch(3)"
            :icon="['fas', 'search']"
            class="pointer fa-search text-white"
            id="search-icon"
          />
        </div>
        <div class="d-flex align-items-center mt-2 f-14">
          <p class="text-gray title-w">ขายคืนเศษทอง?</p>
          <p class="text-gray">:</p>
          <b-form-checkbox class="ml-2" v-model="form.is_return_gold" />
        </div>
        <div class="d-flex align-items-center mt-1 f-14">
          <p class="text-gray title-w f-14">วิธีการรับเงิน</p>
          <p class="text-gray">:</p>
          <b-button class="ml-2 custom-btn" @click="selectRefundType()">
            วิธีการรับเงิน
          </b-button>
        </div>
      </b-col>

      <b-col md="6" cols="12">
        <div class="d-flex align-items-center mt-2 f-14">
          <p class="text-gray">
            เวลาที่คาดว่าจะเสร็จสิ้น&nbsp;&nbsp;&nbsp;&nbsp;:
          </p>
          <div class="datetime-container">
            <datetime
              type="date"
              :format="'dd/MM/yyyy'"
              textFloat="วันหมดอายุใหม่"
              name="expire"
              class="datetime-h ml-2 custom-date-picker"
              v-model="form.finish_date"
              value-zone="Asia/Bangkok"
              :class="['date-picker-input', 'date-filter']"
              :minDatetime="now"
            >
            </datetime>
            <div class="calendar-icon">
              <font-awesome-icon icon="calendar-alt" />
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex align-items-center mt-5">
          <p class="title">สินค้า</p>
          <b-button class="text-right">เลือก</b-button>
        </div>

        <div class="card p-3 mt-1">
          <b-row>
            <b-col md="6" cols="12">
              <div class="d-flex align-items-center mt-2 f-14">
                <p class="text-gray title-w">เลขที่ใบเสร็จ</p>
                <p class="text-gray">:</p>
                <p class="ml-2">1121212</p>
              </div>
              <div class="d-flex align-items-center mt-2 f-14">
                <p class="text-gray title-w">วันที่ซื้อ</p>
                <p class="text-gray">:</p>
                <p class="ml-2">1121212</p>
              </div>
              <div class="d-flex align-items-center mt-2 f-14">
                <p class="text-gray title-w">ชื่อสินค้า</p>
                <p class="text-gray">:</p>
                <p class="ml-2">1121212</p>
              </div>
              <div class="d-flex align-items-center mt-2 f-14">
                <p class="text-gray text-right title-w">น้ำหนัก</p>
                <p class="text-gray">:</p>
                <p class="ml-2">1121212</p>
              </div>
              <div class="d-flex align-items-center mt-2 f-14">
                <p class="text-gray title-w text-right">ขนาด</p>
                <p class="text-gray">:</p>
                <p class="ml-2">1121212</p>
              </div>
            </b-col>
            <b-col md="6" cols="12" align-self="stretch">
              <div class="d-flex align-items-start mt-2 f-14">
                <p class="text-gray title-w">รูปสินค้า</p>
                <p class="text-gray">:</p>
              </div>
            </b-col>
          </b-row>

          <p class="title mt-3">ประวัติการซ่อม</p>
          <RepairLog :fields="fieldLog" :items="itemsLog" class="mt-2" />
        </div>
      </b-col>
      <b-col cols="12" md="4">
        <InputSelect
          :options="repairCauseList"
          v-model="form.repair_cause"
          title="สาเหตุชำรุด"
        >
          <template v-slot:option-first>
            <b-form-select-option :value="null" disabled
              >-- เลือกสาเหตุชำรุด --</b-form-select-option
            >
          </template>
        </InputSelect>
      </b-col>
      <b-col cols="12">
        <InputTextArea
          v-model="form.remark"
          textFloat="หมายเหตุ"
          placeholder="หมายเหตุ"
          rows="3"
        >
        </InputTextArea>
      </b-col>
      <b-col cols="12" md="4">
        <p class="title">Attribute</p>
        <b-table
          striped
          hover
          :busy="isBusy"
          :items="items"
          :fields="fieldsAttribute"
          class="table-main table-custom"
          show-empty
          empty-text="No matching records found"
        >
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <div class="d-flex w-100">
          <InputText
            class="w-50 mr-2"
            placeholder="น้ำหนักเศษทอง"
            textFloat="น้ำหนักเศษทอง"
            disabled
          />
          <InputSelect
            class="w-50"
            :options="repairCauseList"
            v-model="form.repair_cause"
            title="การชุบตัวเรือน"
            disabled
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- รายละเอียด --</b-form-select-option
              >
            </template>
          </InputSelect>
        </div>
      </b-col>
    </b-row>

    <FooterAction
      :routePath="'/repair-product'"
      @submit="confirmAlertModal()"
      textCustom="กฏการรับประกัน"
      @customAction="confirmAlertModal(true)"
      :hideSubmit="isSaved"
    ></FooterAction>
    <ModalAlertConfirm
      ref="modalAlertConfirm"
      :msg="confirmMessage.msg"
      :text="confirmMessage.text"
      @confirm="(val) => saveForm(val)"
    />
    <ModalRefundType ref="modalRefundtype"></ModalRefundType>
    <ModalCustomerList
      :isMobile="false"
      ref="modalCustomerList"
      @setUserSelect="setUserSelect"
    />
    <!-- <ModalProductList
      ref="modalProductList"
      @setSelectProduct="addProduct"
    ></ModalProductList> -->
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "../../../components/inputs/InputTextArea.vue";
import Pagination from "@/components/Pagination";
import FooterAction from "@/components/FooterAction";
import InputSelect from "@/components/inputs/InputSelect";
import { mapGetters } from "vuex";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAddProduct from "../requestPurchase/components/ModalAddProduct";
import ModalCustomerList from "../home/components/ModalCustomerList.vue";
import ModalRefundType from "./components/ModalRefundType.vue";
import RepairLog from "./components/RepairLog.vue";
export default {
  components: {
    InputText,
    InputTextArea,
    Pagination,
    FooterAction,
    ModalAlertConfirm,
    ModalAddProduct,
    ModalCustomerList,
    ModalRefundType,
    RepairLog,
    InputSelect,
  },
  data() {
    return {
      tabIndex: 0,
      isBusy: false,

      filter: {
        search: "",
        page: 1,
        take: 10,
        member: {
          search: "",
          searchType: 0,
        },
      },
      sourceBranch: [
        {
          label: "ชื่อสาขา",
          value: "",
        },
        { label: "รหัส", value: "" },
        { label: "ประเภท", value: "" },
      ],

      productList: [],

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,
      id: this.$route.params.id,
      form: {
        is_return_gold: false,
        customer_name: "",
        technician_name: "",
        finish_date: "",
        remark: "",
        repair_cause: null,
        recive_name: "",
      },
      customerList: [],
      modalCallBack: () => {},
      modalMessage: "",
      isSaved: false,
      confirmMessage: {
        text: "",
        msg: "",
      },
      now: null,
      fieldLog: [
        { label: "เลขเอกสาร", key: "no." },
        { label: "วันที่รับเข้าซ่อม", key: "date" },
        { label: "หัวข้อก่ารซ่อม", key: "title" },
        { label: "บันทึกการซ่อม", key: "log" },
        { label: "ขนาด", key: "seize" },
        { label: "ทอง", key: "scrap_gold" },
        { label: "น้ำหนัก", key: "weight" },
      ],
      itemsLog: [],
      repairCauseList: [],
      fieldsAttribute: [
        { key: "name", label: "รายการ" },
        { key: "before", label: "น้ำหนักก่อนซ่อม" },
        { key: "after", label: "น้ำหนักหลังซ่อม" },
      ],
    };
  },
  created() {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    if (this.$route.params.detail_id != 0) this.getDetail();

    this.sourceBranch = [
      {
        label: "ชื่อสาขา",
        value: this.branchName,
      },
      { label: "รหัส", value: this.plantId },
      { label: "ประเภท", value: this.branchType },
    ];
  },

  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
      plantId: "getPlantId",
      branchName: "getBranchName",
      branchType: "getBranchType",
    }),
  },

  methods: {
    async getDetail() {},
    async handleSearch(type) {
      this.customerList = [];
      this.filter.member.searchType = type;
      this.$refs.modalCustomerList.show(this.filter.member.search);
    },
    setUserSelect(user) {
      if (this.filter.member.searchType == 1) {
        this.form.customer_name = user.first_name_th + " " + user.last_name_th;
      } else if(this.filter.member.searchType == 2) {
        this.form.technician_name =
          user.first_name_th + " " + user.last_name_th;
      }
      else{
        this.form.recive_name = user.first_name_th + " " + user.last_name_th;
      }
    },
    selectRefundType() {
      this.$refs.modalRefundtype.show();
    },
    confirmAlertModal() {
      this.$nextTick();

      if (this.allItem.length == 0) return this.errorAlert("กรุณาเลือกสินค้า");
      this.isDraft = isDraft;
      if (isDraft)
        this.confirmMessage = {
          msg: "ยืนยันการบันทึกฉบับร่าง",
        };
      else
        this.confirmMessage = {
          msg: "ยืนยันการทำรายการ",
          text: "หลังจากยืนยันแล้วจะไม่สามารถแก้ไขได้",
        };
      if (isDraft && this.$route.params.detail_id == 0) {
        this.$refs.modalAlertConfirm.show(isDraft);
      } else if (isDraft && this.$route.params.detail_id != 0) this.saveForm();
      else this.$refs.modalAlertConfirm.show(isDraft);
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  color: #333 !important;
  font-size: 16x !important;
  font-weight: bold !important;
}

.title-w {
  width: 100px;
}

.display-user-value {
  border: #c9c8c8 1px solid;
  width: 230px;
  min-height: 30px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 14px;
  color: #404040;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span {
    color: #404040;
    font-size: 10px;
  }
}

.fa-search {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.custom-btn {
  height: 32px;
}

.calendar-icon {
  position: absolute;
  top: 20%;
  right: 3%;
}
.calendar-icon-top {
  top: 35% !important;
}
.datetime-container {
  position: relative;
  width: 230px;
}

.custom-date-picker ::v-deep input {
  height: 35px !important;
}

.space-bottom {
  padding-bottom: 80px;
}
</style>
